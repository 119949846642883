import '../styles/globals.css';
import { ThemeProvider } from 'next-themes';
import Layout from '../components/layout';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
import { MetaMaskProvider } from 'metamask-react';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useRef } from 'react';
import Script from 'next/script';

import {
  ThemeProvider as ThemeProviderMaterial,
  createTheme,
} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#FCDD08',
      main: '#FCDD08',
      dark: '#FCDD08',
    },
  },
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-9SZXQWEQJS'
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-9SZXQWEQJS');
        `}
      </Script>
      <Script id='hotjar' strategy='afterInteractive'>
        {`(function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 3342111, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`}
      </Script>
      <Meta title='Dinastía Vallenata | Vallenatos viejos, romanticos, viejitos y nuevos' />

      <Provider store={store}>
        <ThemeProvider enableSystem={false} attribute='class'>
          <ThemeProviderMaterial theme={theme}>
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === '/login' ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProviderMaterial>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
